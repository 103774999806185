<template>
  <div>
    <b-card>
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        striped
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(title)="data">
          <b-link :to="{ name: 'wizard-edit', params: { id: data.item.id } }">{{ data.value }}</b-link>
        </template>
        <template #cell(questions)="data">
          {{ data.value.length }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable, BLink } from 'bootstrap-vue'
import store from '@/store'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import wizardStoreModule from './wizardStoreModule'

export default {
  components: {
    BCard,
    BTable,
    BLink,
  },
  setup() {
    const WIZARD_STORE_MODULE_NAME = 'wizards'

    // Register module
    if (!store.hasModule(WIZARD_STORE_MODULE_NAME)) store.registerModule(WIZARD_STORE_MODULE_NAME, wizardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WIZARD_STORE_MODULE_NAME)) store.unregisterModule(WIZARD_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.wizards.tableFields)
    const sortBy = computed(() => store.state.wizards.sortBy)
    const isSortDirDesc = ref(false)

    const fetchData = (ctx, callback) => {
      store.dispatch('wizards/fetchData').then(data => {
        callback(data['hydra:member'])
      })
    }

    return {
      fields,
      sortBy,
      isSortDirDesc,
      fetchData,
    }
  },
}
</script>

<style>

</style>
